<template>
  <b10-base>
    <b10-subheader
      label="Datos del representante"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="nombre"
      :rules="formRules.nombre"
    >
      <v-text-field
        v-model="form.nombre"
        clearable
        flat
        label="Nombre y apellido"
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.dni"
      clearable
      flat
      label="NIF"
      :messages="messagesCIF"
    />
    <v-text-field
      v-model="form.cargo"
      clearable
      flat
      label="Cargo"
    />
    <b10-autocomplete
      v-model="form.ididioma"
      :items="idiomas"
      item-value="ididioma"
      item-text="descripcion"
      label="Idioma"
      clearable
    />
    <v-text-field
      v-model="form.domicilio"
      clearable
      flat
      label="Domicilio social"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="email"
      :rules="formRules.email"
    >
      <v-text-field
        v-model="form.email"
        clearable
        flat
        label="Correo electrónico"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-subheader
      label="Datos del notario"
    />
    <v-text-field
      v-model="form.nombre_notario"
      clearable
      flat
      label="Nombre"
    />
    <v-text-field
      v-model="form.localidad_notario"
      clearable
      flat
      label="Localidad"
    />
    <v-text-field
      v-model="form.protocolo_notario"
      clearable
      flat
      label="Nº de protocolo"
    />
    <b10-date-picker
      v-model="form.fescritura"
      title="Fecha de escritura"
    />
    <b10-textarea
      v-model="form.registro_mercantil"
      class="mt-3"
      label="Registro mercantil"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './RepresentanteFormData'
import { PARAMETRO } from '@/utils/consts'
import { isValidNIF } from '@/utils/validate'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {},
  data () {
    return {
      form: {
        nombre: null,
        ididioma: null,
        dni: null,
        cargo: null,
        domicilio: null,
        email: null,
        nombre_notario: null,
        protocolo_notario: null,
        localidad_notario: null,
        fescritura: null,
        registro_mercantil: null,
      },
      formRules: {
        nombre: { required: true },
        email: { email: true },
      },
      idiomas: [],
      messagesNIF: null,
    }
  },
  computed: {
    messagesCIF () {
      if (!this.form.dni || isValidNIF(this.form.dni)) {
        return []
      } else {
        return ['El formato del NIF no es válido']
      }
    },
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.idiomas = resp.data.selectIdiomas.result.dataset
    const idiomaDefecto = await this.$online.parametro.valorParametro(PARAMETRO.parametros.IDIOMA_DEFECTO)
    if (idiomaDefecto) {
      const existeIdiomaDefecto = _.find(this.idiomas, {ididioma: parseInt(idiomaDefecto)})
      if (existeIdiomaDefecto) {
        this.$set(this.form, 'ididioma', parseInt(idiomaDefecto))
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
  },
}
</script>