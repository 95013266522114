<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <representante-form
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import RepresentanteForm from '../components/RepresentanteForm'
import Data from './RepresentanteAddData'

export default {
  components: {
    RepresentanteForm
  },
  mixins: [formPageMixin],
  props: {
    tipo: {
      type: String,
      default: '',
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir representante'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const inserted = await Data.insert(
        this, this.formData, this.routeParams.idcliente, this.routeParams.idcliente_potencial
      )
      if (this.tipo === 'cliente_potencial') {
        this.$appRouter.replace({
          name: 'representantes__clientes-potenciales-representante-view',
          params: {
            idrepresentante: inserted.idrepresentante,
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (this.tipo === 'cliente') {
        this.$appRouter.replace({
          name: 'representantes__clientes-representante-view',
          params: {
            idrepresentante: inserted.idrepresentante,
            idcliente: this.routeParams.idcliente,
          },
        })
      }
    },
  },
}
</script>
