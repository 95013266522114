export default {
  async insert (Vue, formData, idcliente, idclientePotencial) {
    const resp = await Vue.$api.call(
      'representante.insert',
      {
        values: {
          idcliente: idcliente,
          nombre: formData.nombre,
          domicilio: formData.domicilio,
          dni: formData.dni,
          localidad_notario: formData.localidad_notario,
          nombre_notario: formData.nombre_notario,
          protocolo_notario: formData.protocolo_notario,
          fescritura: formData.fescritura,
          registro_mercantil: formData.registro_mercantil,
          cargo: formData.cargo,
          ididioma: formData.ididioma,
          email: formData.email,
          idcliente_potencial: idclientePotencial,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
